import Container from "react-bootstrap/Container";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Image from 'next/image'
function FeaturedIn(props) {
  return (
    <div className="SpotlightLogos">
      <Carousel
        slidesPerPage={5}
        infinite
        dots
        autoPlay={3000}
        stopAutoPlayOnHover={true}
        breakpoints={{
          640: {
            slidesPerPage: 2,
            arrows: false,
          },
          900: {
            slidesPerPage: 3,
            arrows: false,
          },
          1024: {
            slidesPerPage: 4,
            arrows: false,
          },
        }}
      >
        <a
          href="https://timesofindia.indiatimes.com/business/india-business/refurbished-smartphone-market-enjoys-a-boost-this-festive-season/articleshow/71492468.cms"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
                  src="/images/Spotlight1.webp"
                  width={200}
                  height={80}
                  alt="BBC News"
                  className="w-100 h-auto"
                />
        </a>
        <a
          href="https://budlisell.s3.ap-south-1.amazonaws.com/assets/themes/forntend/default/img/fortune_recommerce.png"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
                  src="/images/Spotlight2.webp"
                  width={200}
                  height={80}
                  alt="BBC News"
                  className="w-100 h-auto"
                />
        </a>
        <a
          href="https://www.bbc.com/news/business-33813128"
          title="Fortune magazine's feature on Budli.in and the ReCommerce space in India"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
                  src="/images/Spotlight3.webp"
                  width={200}
                  height={80}
                  alt="Fortune India Logo"
                  className="w-100 h-auto"
                />
        </a>
        <a
          href="https://www.techinasia.com/talk/india-recommerce-reach-20-billion-untapped"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
                  src="/images/Spotlight4.webp"
                  width={200}
                  height={80}
                  alt="Inc 42 logo"
                  className="w-100 h-auto"
                />
        </a>
        <a
          href="https://inc42.com/startups/budli/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
                  src="/images/Spotlight5.webp"
                  width={200}
                  height={80}
                  alt="The Times Of India logo"
                  className="w-100 h-auto"
                />
        </a>
        <a
          href="https://yourstory.com/2019/04/recommerce-budli-refurbished-smartphone-electronic-devices"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
                  src="/images/Spotlight6.webp"
                  width={200}
                  height={80}
                  alt="EntrepreneurIndia logo"
                  className="w-100 h-auto"
                />
        </a>
        <a
          href="https://www.entrepreneur.com/article/276286"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
                  src="/images/Spotlight7.webp"
                  width={200}
                  height={80}
                  alt="Tech In Asia logo"
                  className="w-100 h-auto"
                />
        </a>
      </Carousel>
    </div>
  );
}

export default FeaturedIn;
